/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Heading from "../../components/Heading/Heading";
import Footer from "../../components/Footer/Footer";
import SmallBanner from "../../components/SmallBanner/SmallBanner";
import { Container } from "react-bootstrap";

const slides = [
  {
    id: "1",
    title: "Immortal Process",
    subTitle: "",
    desc: [],
    bannerImg: "eshwari-shakthi-kendra-sadhana-1.png",
  },
];

export default class ImmortalProcess extends Component {
  render() {
    return (
      <div>
        <Header />
        <SmallBanner slides={slides} bannerClass="pt-0 small-banner-section" />
        <section>
          <Container>
            <Heading
              smalltitle="Sadhana & Dhyana"
              title="Immortal Process"
              specialTitle=""
            />
            <div className="text-justify">
              <p>
                The theory of "karma and rebirth" raises numerous questions such
                as how, when, and why did the cycle start in the first place,
                what is the relative Karmic merit of one karma versus another
                and why, and what evidence is there that rebirth actually
                happens, among others.
              </p>
              <p>
                Various schools of Hinduism realized these difficulties, debated
                their own formulations, some reaching what they considered as
                internally consistent theories, while other schools modified and
                de-emphasized it, while a few schools in Hinduism such as
                Chaevakas, Lokayatana abandoned "karma and rebirth" theory
                altogether.
              </p>
              <p>
                Schools of Buddhism, Jainism, Taoism and Sikhism consider
                karma-rebirth cycle as integral to their theories of soteriology
                ( is the study of religious doctrines of salvation ).
              </p>
              <p>
                The Patanjali Yoga sutra discussed the idea of the inner being
                becoming burdened with an accumulation of impressions as part of
                the karma from previous lives.
              </p>
              <p>
                Patañjali called the process of past-life regression as
                prati-prasav (literally "reverse birthing"), and saw it as
                addressing current problems through memories of past lives. Some
                types of yoga continue to use prati-prasav as a practice.
              </p>
              <p>
                Vedic philosophy, Upanishads and Bhagavad-Gita also supports the
                theory of Karma and Re-birth
              </p>
              <p>
                <b>Brihadaranyaka Upanishad Verse 4.4.17</b>
              </p>
              <p>
                यस्मिन्पञ्च पञ्चजना आकाशश्च प्रतिष्ठितः । तमेव मन्य आत्मानं
                विद्वान्ब्रह्मामृतोऽमृतम् ॥ १७ ॥
              </p>
              <p>
                yasmin pañca pañcajanā ākāśaśca pratiṣṭhitaḥ | tameva manya
                ātmānaṃ vidvānbrahmāmṛto'mṛtam || 17 ||
              </p>
              <p>
                That in which the five groups of five and the (subtle) ether are
                placed, that very Ātman I regard as the immortal Brahman.
                Knowing (Brahman) I am immortal.//17//
              </p>
              <p>
                Moreover, that Brahman in which the five groups of five, the
                celestial minstrels etc., who are five in number, viz. the
                celestial minstrels, the Manes, the gods, the Asuras and the
                Rākṣasas—or the four castes with the Caṇḍālas as the fifth and
                the ether called the Undifferentiated, which pervades the Sūtra,
                are placed—it has been said, ‘By this Immutable, O Gārgī, is the
                (un manifested) ether pervaded’ (III. viii. 11) that very Ātman
                I regard as <b>the immortal Brahman.</b> I do not consider the
                Self as different from that. What then is it?
                <b>Knowing Brahman, I am immortal.</b>
              </p>
              <p>
                <b>
                  // I was mortal only through ignorance, since that is gone, I,
                  the knowing one, am indeed immortal //
                </b>
              </p>
              <p>
                The purpose of immortal process workshop is to make the sadhak
                realize that he/she is immortal , the mortal is the body the
                jeevatman is immortal .
              </p>
              <p>
                Knowing and realizing this truth one can lead fearless, worthy
                and holistic life, which is known as spiritual life, spiritual
                means knowledge of the inner being.
              </p>
              <h2 className="title-small">
                The effect of karma and the Rebirth:
              </h2>
              <p>
                Now as a man is like this or like that, according as he acts and
                according as he behaves, so will he be; a man of good acts will
                become good, a man of bad acts, bad; he becomes pure by pure
                deeds, bad by bad deeds; And here they say that a person
                consists of desires, and as is his desire, so is his will; and
                as is his will, so is his deed; and whatever deed he does, that
                he will reap.- Brihadaranyaka Upanishad
              </p>
              <p>
                The relationship of karma to causality is a central motif in all
                schools of Hindu, Jain and Buddhist thought. The theory of karma
                as causality holds that executed actions of an individual
                affects the individual and the life he or she lives, and the
                intentions of an individual affects the individual and the life
                he or she lives.
              </p>
              <p>
                Disinterested actions or unintentional actions do not have the
                same positive or negative karmic effect, as interested and
                intentional actions. In Buddhism, for example, actions that are
                performed, or arise, or originate without any bad intent such as
                covetousness, are considered non-existent in karmic impact or
                neutral in influence to the individual.
              </p>
              <p>
                Another causality characteristic, shared by Karmic theories, is
                that like deeds lead to like effects. Thus good karma produces
                good effect on the actor, while bad karma produces bad effect.
                This effect may be material, moral or emotional that is, one's
                karma affects one's happiness and unhappiness. The effect of
                karma need not be immediate; the effect of karma can be later in
                one's current life, and in some schools it extends to future
                lives.
              </p>
              <p>
                The consequence or effects of one's karma can be described in
                two forms: phalas and samskaras-effect. A phala-results
                (literally fruit or result) is the visible or invisible effect
                that is typically immediate or within the current life. In
                contrast, samskaras are invisible effects, produced inside the
                actor because of the karma, transforming the agent and affecting
                his or her ability to be happy or unhappy in this life and
                future ones. The theory of karma is often presented in the
                context of samskaras.
              </p>
              <p>
                Karmic principle can be understood as a principle of psychology
                and habit. Karma seeds habits (Vasana), and habits create the
                nature of man. Karma also seeds self perception, and perception
                influences how one experiences life events. Both habits and
                self-perception affect the course of one's life. Breaking bad
                habits is not easy: it requires conscious karmic effort.
              </p>
              <p>
                Thus psyche and habit, according to Potter and others, link
                karma to causality in ancient Indian literature. The idea of
                karma may be compared to the notion of a person's "character",
                as both are an assessment of the person and determined by that
                person's habitual thinking and acting.
              </p>
              <h2 className="title-small">Rebirth</h2>
              <p>
                The third common theme of karma theories is the concept of
                reincarnation or the cycle of rebirths (saṃsāra).
              </p>
              <p>
                Rebirth is a fundamental concept of Hinduism, Buddhism, Jainism
                and Sikhism. The concept has been intensely debated in ancient
                literature of India; with different schools of Indian religions
                considering the relevance of rebirth as either essential, or
                secondary, or unnecessary fiction.
              </p>
              <p>
                Rebirth, or saṃsāra, is the concept that all life forms go
                through a cycle of reincarnation that is a series of births and
                rebirths. The rebirths and consequent life may be in different
                realm, condition or form. The karma theories suggest that the
                realm, condition and form depend on the quality and quantity of
                karma. In schools that believe in rebirth, every living being's
                soul transmigrates (recycles) after death, carrying the seeds of
                Karmic impulses from life just completed, into another life and
                lifetime of karmas. This cycle continues indefinitely, except
                for those who consciously break this cycle by reaching Moksha.
                Those who break the cycle reach the realm of gods, those who
                don't continue in the cycle.
              </p>
              <p>
                <b>The Bhagavad-gita says:</b>
              </p>
              <p>
                "As the embodied soul continuously passes, in this body, from
                boyhood to youth to old age, the soul similarly passes into
                another body at death."(Bg 2.13)
              </p>
              <p>
                "As a person puts on new garments, giving up old ones, the soul
                similarly accepts new material bodies, giving up the old and
                useless ones."(Bg 2.22)
              </p>
              <p>
                So what is your opinion on the above religious beliefs or do you
                have any questions in your mind about the following ?
              </p>
              <ul>
                <li>If I had past lives, why don't I remember them </li>
                <li>
                  Since we don't remember them, what could be the use of them
                </li>
                <li>
                  If reincarnation is fact, why is the population increasing
                </li>
                <li>
                  Other major religious books like Bible and Quran denies
                  reincarnation
                </li>
                <li>
                  Do the past lives really has good and bad effects on our
                  present life
                </li>
              </ul>
              <p>
                Immortal process is such a process which makes the individual to
                experience their past lives and the effect of it on our present
                life.
              </p>
              <p>
                I and YOU are non-perishable, we are not mortal, we are not
                going to end this life, we have been born on this planet many
                times, lived many lives in many forms.
              </p>
              <p>
                Experience your immortality, the eternal reality through
                “IMMORTAL PROCESS” Each individual enters the Mother's womb with
                different names and different forms, once born the individual
                forgets the other side of his life's memories.
              </p>
              <ul>
                <li>The memory that might be filled with great skills, </li>
                <li>Great knowledge, </li>
                <li>Great ability, </li>
                <li>Greater innovations and </li>
                <li>Great talents</li>
              </ul>
              <p>
                One can unfold the stored yet, forgotten memories to unfold Past
                life's skills, knowledge, abilities, ideas and talents by
                discovering or recovering the other side of forgotten memories
                in the meantime one can clear their
              </p>
              <ul>
                <li>Bad Karma, Curses, Doshas </li>
                <li>One gets relieved of Negative Psychic attachments </li>
                <li>One can clear Negative Mind Patterns </li>
                <li>
                  One can get relief from Spell, Evil eye, Black magic and many
                  more
                </li>
              </ul>
              <p>
                The immortal process or Prati-Prasava (past life regression)
                process is a tool that provides for recalling events in our past
                lives that may be the root cause of current patterns and
                behaviors.
              </p>
              <p>
                These patterns can be mental or physical and can be something
                that needs to be changed or merely understood. The process helps
                individuals to clear past impressions that may be blocking their
                progress in life and thus begin to live more in the present
                moment.
              </p>
              <p>
                The immortal process is a one to one session with Guruji that
                lasts 30 to 45 minutes and the experience is similar to a deep
                guided meditation.
              </p>
              <p>
                <b>
                  <span
                    clasName="lora-text"
                    style={{ fontFamily: "Lora", fontSize: "20px" }}
                  >
                    Jai Guru Dev
                  </span>
                </b>
              </p>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    );
  }
}
