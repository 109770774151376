/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Heading from "../../components/Heading/Heading";
import Footer from "../../components/Footer/Footer";
import SmallBanner from "../../components/SmallBanner/SmallBanner";
import { Container } from "react-bootstrap";

const slides = [
  {
    id: "1",
    title: "Jyothishmathi – Astro/Vastu Solutions",
    subTitle: "",
    desc: [],
    bannerImg: "eshwari-shakthi-kendra-sadhana-1.png",
  },
];

export default class Jyothishmathi extends Component {
  render() {
    return (
      <div>
        <Header />
        <SmallBanner slides={slides} bannerClass="pt-0 small-banner-section" />
        <section>
          <Container>
            <Heading
              smalltitle=""
              title="Jyothishmathi"
              specialTitle="– Astro/Vastu Solutions"
            />
            <div className="text-justify">
              <p>
                The school of <b>Jyothishmathi - Astro/Vastu solutions</b> is
                founded by Guruji Sri Prasad, a profound Srividhya Yoga Tantra
                Master , who is fully established in the Self. Our school seeks
                to expand the world’s understanding of yoga through an education
                and training that are steeped in the Shaktha tradition, the
                original roots of yoga tantra, Astrology and Vastu , a long
                lineage of masters while making yoga tantra, Astrology and Vastu
                accessible and applicable for modern day use for people of all
                ages, abilities, cultures and religions.
              </p>
              <p>
                <b>
                  //yatha pinde tatha brahmhande- as is the atom so is the
                  universe - as is the body so is the cosmic body //
                </b>
              </p>
              <p>
                Yogic science says that it is mala, or impurity, that builds
                walls between the individual self and the truth of oneness with
                the universal Self. Through Eshwari Sadhana practices and
                progress on the spiritual path, these walls can be removed.
              </p>
              <p>
                The meaning of yatha pinde tatha brahmande implies that all
                separation is a construct and an illusion. Through yogic science
                and Eshwari practices, Sadhaks can break through the illusion of
                separation and become Self-realized, experiencing the truth of
                yatha pinde tatha brahmande.
              </p>
              <p>
                Yatha pinde tatha brahmande can be chanted repeatedly as a
                mantra, in the practice of Srividhya Eshwari sadhana this can
                help pierce the chakras, allowing for accelerated spiritual
                development,as is the atom so is the universe - as is the body
                so is the cosmic body , so it clearly says that , if one should
                live a good healthy, wealthy Holistic life then he/she should
                take care of three expansions of our own existence towards our
                existential reality, the three expansions are our Body(Deha) ,
                Our Home(Gruha) and Our Planetary conditions (Graha)
              </p>
              <p>
                Deha, Gruha and Graha should be alligned as per nature's law,
                the science of our individual existence is thought through
                Eshwari sadhana, the knowledge of the Nature and the Cosmos is
                Jyothishmathi - Astro & Vastu whork shop.
              </p>
              <p>
                <b>
                  Jyothishmathi is a Light on astrology and Vastu to illuminate
                  lives
                </b>
              </p>
              <p>
                The Study of Jyothshmathi can be traced to the Vedic times.
                Glimpses of Astrology Traditions are visible in the
                ‘Atharva-Veda’, one among the most sacred texts of India.
                Ancient Indian Vedic Astrology in India, more popularly known as
                Jyotish, relies more on the Sidereal ( according to the Stars )
                positions of the Planets, just as one sees them in the Sky,
                against the positions assigned to few relatively fixed Stars.
              </p>
              <p>
                This Ancient system of Indian Vedic Astrology is thus scientific
                in Nature and employs many Complex Mathematical Calculations to
                arrive at an astute Astrology Predictions and Prophecies.
              </p>
              <p>
                Jyothishmathi is an ancient Indian Science which explains
                Planetary Motions and Positions with respect to Time and their
                Effects on Humans and other Entities on Earth. Jyothishmathi can
                be traced to thousands of years back. Early Vedic Astrology was
                only based on the movement of Planets with Respect to Stars, but
                later on it started including Zodiac Signs as well.
              </p>
              <p>
                Depending on when a Person is Born, the 12 Signs are distributed
                among the 12 Houses and 9 Planets which are placed in Various
                Houses of the Kundali.
              </p>
              <p>
                This visual representation of the snapshot of the Signs and
                Planets is called a Kundali / Horoscope Chart.
              </p>
              <p>
                Jyothishmathi is nothing but interpreting the meaning of these
                arrangements as it applies to Humans and Other Entities.
              </p>
              <p>
                According to Indian Vedic Astrology in India, or celestial
                bodies, be it the Sun, The Moon, The Planets or stars, influence
                the Terrestrial Phenomena, or indicate such Phenomena by their
                various Configurations. The Configurations of the Planets and
                Stars at the moment of Birth determines one’s basic Life Story
                Inclinations, Characteristics, Strengths, Weaknesses. Horoscope,
                or the Natal Chart, is therefore the Construction of the Basic
                placement of Planets at the time of Birth. That is why it is
                termed as a Janam-Patra in Astrology
              </p>
              <p>
                Jyothishmathi workshop is the subject which predicts life events
                of a person from his/her birth chart, drawn according to the
                birth time, date & place. Jyothishmathi uses sidereal
                astronomical calculations (called Drikganit) for calculating
                planetary positions at the time of the person's birth.
              </p>
              <p>
                From the jyothismathi - Astro/Vastu work shop one can learn to
                read the Horoscope chart , abilities to identify Dosha and
                Yogas, Fixing Muhurtha, Marriage matching and to give remedies.
              </p>
              <h2 className="title">Jyothishmathi - Vastu</h2>
              <p>
                This complete knowledge of building is as old as the structure
                of the universe. At least the structure of the universe and
                everything in it is laid out according to the laws of nature.
                Jyothishmathi-Vastu is that ancient science of building and
                design that is based on the same wholeness of Natural Law that
                the entire cosmos is based on.
              </p>
              <p>
                Jyothishmathi-Vastu is that knowledge of how to design
                Fortune-Creating homes & Buildings from the level of the Cosmic
                architecture, the eternal design of the universe in accordance
                with the structuring dynamics of the field of Total Knowledge.
              </p>
              <p>
                Every individual is the expression of Natural Law, and should
                naturally be in harmony with himself and with his universe and
                if there is some knowledge which can be helpful to harmonize
                human creation (man-made structures) with nature’s creation, it
                is wise to make use of this knowledge so that individual life
                may enjoy that supreme level of order and harmony of Natures'
                Law which belongs to the universe, and to the essential nature
                of every individual.
              </p>
              <p>
                Then the buildings that we live and work in do not unknowingly
                create problems for us but become Fortune-Creating homes and
                buildings that constantly support all our activities for
                effortless success, good health and happy, harmonious family
                life! from the Jyothismathi - Vastu work shop one can learn to
                read/write the Building plans , abilities to identify Dosha and
                Yogas and to give remedies, the participants will be thought to
                identify the scientific method of identifying the weak and
                strong zones of energy at Home / Buildings and the treatments
                for correction without breaking the walls and shifting the Doors
                or Windows and without even installing any kind of Pyramids,
                Crystals or any Feng shui methods, the remedies taught are very
                simple yet effective and scientific ways of treating problems.
              </p>

              <p>
                Jyothishmathi - Astro/Vastu Learn a simple way of reading,
                identifying the Doshas (Bad effects) from Horoscopes and Living
                Place in just 2 Days, each workshop takes 18 hours to learn and
                practice.
              </p>
              <p>
                <b>
                  Jyothishmathi is a Light on astrology and Vastu to illuminate
                  lives
                </b>
              </p>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    );
  }
}
